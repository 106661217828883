.button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #FF9900;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.button:hover,
.button:active {
  background: #ffae00;
}
